.footer {
  width: 100%;
  padding: 60px 0;
  padding-bottom: 0px;
}

.footer__container {
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.footer__content {
  display: flex;
  justify-content: space-between;
}

.footer__left {
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.footer__title {
  font-family: var(--font-secondary);
  font-size: var(--font-size-large);
  line-height: var(--line-height-normal);
  color: var(--color-white);
  margin-bottom: var(--spacing-medium);
}

.footer__contacts {
  font-family: var(--font-primary);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-medium);
  line-height: 10px;
  color: var(--color-secondary);
}

.footer__contacts a {
  color: var(--color-white);
  padding-left: 5px;
}

.footer__contacts--comma {
  color: var(--color-white);
}

.footer__buttons {
  display: flex;
  gap: 25px;
}

.footer__logos {
  display: flex;
}

.footer__logo {
  width: 144px;
  height: 144px;
  background-size: contain;
  background-repeat: no-repeat;
}

.footer__logo--cybersecurity {
  background-image: url("../assets/share-vk.png");
}

.footer__logo--ctf {
  background-image: url("../assets/share-tg.png");
}

.footer__ctf-text {
  font-family: var(--font-secondary);
  font-weight: var(--font-weight-medium);
  font-size: 28px;
  line-height: var(--line-height-normal);
  text-align: center;
  color: var(--color-secondary);
}

.footer__decorative-blocks {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-right: 80px;
}

.footer__decorative-blocks--small {
  padding-right: 224px;
}

.footer__decorative-blocks--end {
    display: none;
}
.footer__block {
  width: 144px;
  height: 144px;
}

.footer__block--small {
  width: 72px;
  height: 73px;
  background-color: var(--color-primary);
}

.footer__block--red {
  background-color: var(--color-secondary);
}

.footer__block--blue {
  background-color: var(--color-primary);
}

.footer__block--red-2 {
  background-color: var(--color-secondary);
}

.footer__logo-item {
  display: none;
}

.footer__logo-image {
  width: 96px;
  height: 91px;
}

.footer_logo--link {
  padding: 30px;
}

@media (max-width: 767px) {
  .footer__decorative-blocks,
  .footer__decorative-blocks--small {
    justify-content: flex-start;
    padding-right: 0;
  }

  .footer__content {
    flex-direction: column;
  }

  .footer__logo-item {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }

  .footer__decorative-blocks--large {
    display: none;
  }

  .footer__decorative-blocks--end {
    display: block;
    justify-content: flex-start;
    padding-left: 213px;
  }

  .footer__logo {
    width: 70px;
    height: 70px;
  }

  .footer__logos {
    padding-left: 73px;
  }

  .footer_logo--link {
    background: var(--color-secondary);
    width: 100%;
    height: 70px;
  }

  .footer__ctf-text {
    color: var(--color-background);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-medium);
  }
  .footer_logo--link {
    padding: 25px;
    padding-bottom: 0px;
}
}
