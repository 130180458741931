.jury {
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: url("../assets/jury.png") center top no-repeat;
  width: 100%;
  background-size: 100% 105%;
  position: relative;
  max-width: var(--max-width);
  margin: 0 auto;
  padding-bottom: 200px;
}

.jury__title {
  font-family: "Quantum", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 4.5rem;
  line-height: 1.25;
  text-align: center;
  color: #d44954;
  margin-bottom: 3rem;
}

.jury__members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
  max-width: 1200px;
  width: 100%;
  padding-top: 100px;
}

.jury__member {
  width: calc(25% - 3rem);
  min-width: 164px; /* Ensure minimum width */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.jury__member-image {
  width: 164px;
  height: 164px;
  object-fit: cover;
  margin-bottom: 1rem;
}

.jury__member-info {
  text-align: left;
  padding-left: 44px;
}

.jury__member-name {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5;
  color: #649ab0;
  margin-bottom: 0.5rem;
}

.jury__member-position {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
}

.jury__decorations {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 3rem;
}

.jury__decoration {
  width: 71px;
  height: 71px;
}

.jury__decoration--red-small {
  background: #d44954;
}

.jury__decoration--blue-small {
  background: #649ab0;
}

.jury__decoration--blue-large {
  width: 144px;
  height: 144px;
  background: #649ab0;
}

.jury__decoration--red-large {
  width: 144px;
  height: 144px;
  background: #d44954;
}

@media (max-width: 768px) {
  .jury {
    background: url("../assets/jury-mobile.png") center top no-repeat;
    background-size: 100% 100%;
  background-size: cover;

  }

  .jury__title {
    font-size: 3rem;
  }

  .jury__members {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin-bottom: 30px;
  }

  .jury__member {
    width: 100%;
    max-width: 199px;
  }

  .jury__member-info {
    text-align: left;
    padding-left: 10px;
    max-width: 150px;
  }

  .jury__member-image {
    width: 143px;
    height: 143px;
    margin-bottom: 20px;
  }

  .jury__member-name {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 8px;
  }

  .jury__decorations {
    display: none;
  }
}
