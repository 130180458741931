.registration-ru {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.registration-ru h1 {
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 71px;
}

.registration-form-container {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.result img {
  width: 100%;
  padding-top: 20px;
}

.result_mobile img {
  width: 100%;
  display: none;
  padding-top: 20px;
}

iframe {
  width: 100%;
  border: none;
  overflow: hidden;
  height: 1040px;
}

@media (max-width: 768px) {
  .registration-ru {
    padding: 10px;
  }

  .registration-ru h1 {
    font-size: 25px;
  }

  .registration-form-container {
    padding: 10px;
  }

  iframe {
    height: 1250px;
  }

  .result img {
    width: 100%;
    display: none;
  }

  .result_mobile img {
    width: 100%;
    display: block;
  }

  .registration-text {
    padding: 0px !important;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.registration-button--text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  text-align: center;

  color: #ffffff;

  padding-bottom: 30px;
}

.registration-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  text-align: center;

  color: #ffffff;
  text-transform: uppercase;
  padding: 30px;
}

.registration-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 30px;
  flex-direction: column;
  margin-top: 100px;
}

.registration-button::before,
.registration-button::after,
.registration-button > :first-child::after,
.registration-button > :first-child::before {
  content: "";
  position: absolute;
  width: 45.45px;
  height: 45.45px;
  border: 7px solid #ffffff;
}

.registration-button::before {
  top: 0;
  right: 0;
  border-left: none;
  border-bottom: none;
}

.registration-button::after {
  bottom: 0;
  right: 0;
  border-left: none;
  border-top: none;
}

.registration-button > :first-child::after {
  bottom: 0;
  left: 0;
  border-right: none;
  border-top: none;
}

.registration-button > :first-child::before {
  top: 0;
  left: 0;
  border-right: none;
  border-bottom: none;
}
