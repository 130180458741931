@font-face {
  font-family: "Quantum";
  src: url("../fonts/Quantum.otf") format("opentype");
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

:root {
  /* Fonts */
  --font-primary: "Roboto", sans-serif;
  --font-secondary: "Quantum", sans-serif;

  /* Colors */
  --color-primary: #4f7892;
  --color-secondary: #d44954;
  --color-white: #ffffff;
  --color-background: #0c0c0c;

  /* Sizes */
  --max-width: 1440px;

  /* Font sizes */
  --font-size-small: 18px;
  --font-size-medium: 24px;
  --font-size-large: 38px;
  --font-size-xl: 71px;
  --font-size-xxl: 130px;

  /* Font weights */
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  /* Line heights */
  --line-height-normal: 1.17;
  --line-height-large: 1.25;

  /* Spacing */
  --spacing-small: 1rem;
  --spacing-medium: 2rem;
  --spacing-large: 4rem;
}

body {
  font-family: var(--font-primary);
  background: var(--color-background);
  color: var(--color-white);
  padding-top: 115px;
}

h1,
h2,
h3 {
  font-family: var(--font-secondary);
  text-transform: uppercase;
}

a {
  text-decoration: none;
}

@media (max-width: 767px) {
  :root {
    /* Font sizes */
    --font-size-small: 12px;
    --font-size-medium: 15px;
    --font-size-large: 25px;
    --font-size-xl: 71px;
    --font-size-xxl: 130px;
  }
}
