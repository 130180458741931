.team {
  background: url('../assets/team.png')  center top no-repeat;
  width: 100%;
  background-size: cover;
  position: relative;

  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: var(--max-width);
  margin: 0 auto;
}
.team__title {
  font-family: 'Quantum', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 71px;
  line-height: 65px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 100px;
  max-width: 652px;
  margin-left: auto;
  margin-right: auto;
}

.team__title--committee {
  color: var(--color-secondary)
}

.team__members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
  max-width: 1200px;
  width: 100%;
}

.team__member {
  width: calc(25% - 3rem);
  min-width: 164px; /* Ensure minimum width */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team__member-image {
  width: 164px;
  height: 164px;
  object-fit: cover;
  margin-bottom: 1rem;
}

.team__member-info {
  text-align: left;
  padding-left: 44px;
  width: 210px;
}

.team__member-name {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5;
  color: #649AB0;
  margin-bottom: 0.5rem;
}

.team__member-position {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #FFFFFF;
}

.team__decorations {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.team__decoration {
  width: 71px;
  height: 71px;
}

.team__decoration--red {
  background: #D44954;
}

.team__decoration--blue {
  background: #649AB0;
}

.team__decoration--large {
  width: 144px;
  height: 144px;
}

.team__section--project .team__decoration--blue-group {
  display: grid;
  grid-template-columns: repeat(2, 71px);
  grid-template-rows: repeat(2, 71px);
  gap: 71px;
}

.team__section--project .team__decoration--blue-group div {
  background: #649AB0;
}

.team__section--project {
  padding-top: 200px;
}

@media (max-width: 1200px) {
  .team__members {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  .team__members {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .team {
    background: url('../assets/team_mobile.png') center top no-repeat;
    background-size: 100% 100%;
  }

  .team__title {
    width: 284px;
    font-family: 'Quantum', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    margin: 0 auto 50px;
  }

  .team__members {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin-bottom: 30px;
  }

  .team__member {
    width: 100%;
    max-width: 199px;
  }

  .jury__member-info {
    text-align: left;
    padding-left: 10px;
    max-width: 150px;
  }

  .team__member-image {
    width: 143px;
    height: 143px;
    margin-bottom: 20px;
  }

  .team__member-name {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #D44954;
    margin-bottom: 8px;
    max-width: 175px;
  }

  .team__member-info {
    width: 185px;
  }

  .team__member-position {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #FFFFFF;
    max-width: 150px;
  }

  .team__decorations {
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 20px;
    justify-content: space-between;
    margin-top: 30px;
  }

  .team__decoration {
    width: 22px;
    height: 22px;
  }

  .team__decoration--blue {
    background: #649AB0;
  }

  .team__decoration--red {
    background: #D44954;
  }

  .team__decoration--large {
    width: 34.95px;
    height: 35.43px;
  }

  .team__section--project {
    padding-top: 0px;
  }
}