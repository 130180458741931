.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0c0c0c;
  z-index: 1000;
  transition: transform 0.3s ease;
}

.header--visible {
  transform: translateY(0);
}

.header--hidden {
  transform: translateY(-100%);
}

.header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 71px;
  width: 100%;
  max-width: var(--max-width);
  padding: 0 163px;
  box-sizing: border-box;
}

.header__logo {
  width: 63px;
  height: 55px;
}

.header__registration {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-small);
  line-height: 21px;
  color: var(--color-secondary);
  text-decoration: none;
}

.header__nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
}

.header__nav-link {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-small);
  line-height: 21px;
  text-align: center;
  color: var(--color-primary);
  text-decoration: none;
  text-transform: uppercase;
}

.header__line {
  width: 100%;
  height: 4px;
  background: var(--color-primary);
}

.header__menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.header__menu-toggle span {
  width: 30px;
  height: 3px;
  background: var(--color-white);
}

@media (max-width: 768px) {
  .header__content {
    padding: 0 20px;
  }

  .header__registration {
    color: var(--color-white);
    font-family: var(--font-secondary);
    font-weight: var(--font-weight-normal);
  }

  .header__logo-link {
    display: none;
  }

  .header__menu-toggle {
    display: flex;
  }

  .header__nav {
    position: absolute;
    top: 71px;
    left: 0;
    width: 100%;
    flex-direction: column;
    background-color: #0c0c0c;
    display: none;
    gap: 20px;
  }

  .header__nav--open {
    display: flex;
  }

  .header__nav-link {
    padding: 15px 0;
    width: 100%;
    text-align: center;
    font-size: var(--font-size-medium);
  }
}
