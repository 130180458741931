

.legend__title {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: var(--font-weight-normal);
  font-size: 71px;
  line-height: 89px;
  text-align: center;
  color: var(--color-white);
}

.legend__description {
  max-width: 977px;
  margin: 131px auto 0;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: var(--font-weight-normal);
  font-size: 29px;
  line-height: 34px;
  text-align: center;
  color: var(--color-white);
}

.legend__items {
  max-width: 1190px;
  margin: 133px auto 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 187px 258px;
  justify-items: center;
  padding: 0 20px;
}

.legend__item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.legend__item--icon {
  width: 261px;
  height: 314px;
  display: block;
}

.legend__item--icon-red {
  background: url("../assets/doc-red.png");
  background-size: 261px 314px;
}

.legend__item--icon-blue {
  background: url("../assets/doc-blue.png");
  background-size: 261px 314px;
}

.legend__item--title {
  margin-top: 43px;
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: var(--font-weight-normal);
  font-size: 38px;
  line-height: 48px;
  text-align: center;
  color: var(--color-white);
}

@media (max-width: 768px) {
  .legend__title {
    font-size: 40px;
  }

  .legend__description {
    margin-top: 60px;
    font-size: 20px;
    padding: 0 20px;
  }

  .legend__items {
    margin-top: 60px;
    grid-template-columns: 1fr;
    gap: 60px;
  }

  .legend__item--icon {
    width: 200px;
    height: 240px;
    background-size: 200px 240px;
  }

  .legend__item--title {
    font-size: 30px;
  }}