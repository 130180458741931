.partners {
  padding: 0px 20px;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.partners__header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.newno {
  display: none;
}

.partners__title {
  font-family: "Quantum", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 71px;
  line-height: 89px;
  text-align: center;
  color: #d44954;
}

.partners__rectangle {
  width: 71px;
  height: 71px;
  background: #d44954;
  margin-left: 20px;
}

.partners__subtitle {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 150%;
  color: #649ab0;
  margin-bottom: 50px;
}

.partners__partners-section .partners__subtitle-general {
  margin-bottom: -147px;
}

.partners__background {
  background: url("../assets/partners.png") center no-repeat;
  width: 100%;
  background-size: 100% auto;
  position: relative;
}

.partners__content {
  margin-bottom: 50px;
}

.partners__organizers {
  width: 100%;
  max-width: 300px;
  margin-bottom: 100px;
  position: relative;
}

.partners__subtitle {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 150%;
  color: #649ab0;
  z-index: 1;
  margin-bottom: -35px;
}

.partners__organizers-border {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 50px;
  padding-bottom: 149px;
  padding-top: 147px;
}

.partners__organizers-border::before,
.partners__organizers-border::after,
.partners__organizers-border > :first-child::after {
  content: "";
  position: absolute;
  width: 45.45px;
  height: 45.45px;
  border: 7px solid #649ab0;
}

.partners__organizers-border::before {
  top: 0;
  right: 0;
  border-left: none;
  border-bottom: none;
}

.partners__organizers-border::after {
  bottom: 0;
  right: 0;
  border-left: none;
  border-top: none;
}

.partners__organizers-border > :first-child::after {
  bottom: 0;
  left: 0;
  border-right: none;
  border-top: none;
}

.partners__organizers-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 60px;
}

.partners__organizer-image--arsib {
  width: 205px;
  height: 108px;
}

.partners__organizer-image--nastavniki {
  width: 94px;
  height: 126px;
}

.partners__block {
  display: flex;
  justify-content: space-between;
}

.partners__join {
  position: relative;
  width: 400px;
}

.partners__join-border {
  position: relative;
  padding: 39px 45px;
  padding-bottom: 50px;
}

.partners__join-border::before,
.partners__join-border::after,
.partners__join-border > :first-child::before,
.partners__join-border > :first-child::after {
  content: "";
  position: absolute;
  width: 45.45px;
  height: 45.45px;
  border: 7px solid #649ab0;
}

.partners__join-border::before {
  top: 0;
  right: 0;
  border-left: none;
  border-bottom: none;
}

.partners__join-border::after {
  bottom: 0;
  right: 0;
  border-left: none;
  border-top: none;
}

.partners__join-border > :first-child::before {
  top: 0;
  left: 0;
  border-right: none;
  border-bottom: none;
}

.partners__join-border > :first-child::after {
  bottom: 0;
  left: 0;
  border-right: none;
  border-top: none;
}

.partners__join-title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 130%;
  color: #649ab0;
  margin-bottom: 9px;
}

.partners__join-text {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.78);
  margin-bottom: 37px;
}

.partners__join-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 27px;
  width: 100%;
  height: 43px;
  border: 1px solid #d44954;
  border-radius: 79px;
  background: transparent;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: var(--font-weight-medium);
  font-size: 18px;
  line-height: 21px;
  color: #d44954;
  cursor: pointer;
}

.partners__official {
  width: 100%;
  max-width: 720px;
  margin-bottom: 100px;
}

.partners__official-title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 150%;
  color: #649ab0;
  margin-bottom: -35px;
}

.partners__official-border {
  position: relative;
  padding: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partners__official-border::before,
.partners__official-border::after,
.partners__official-border > :first-child::after {
  content: "";
  position: absolute;
  width: 45.45px;
  height: 45.45px;
  border: 7px solid #649ab0;
}

.partners__official-border::before {
  top: 0;
  right: 0;
  border-left: none;
  border-bottom: none;
}

.partners__official-border::after {
  bottom: 0;
  right: 0;
  border-left: none;
  border-top: none;
}

.partners__official-border > :first-child::after {
  bottom: 0;
  left: 0;
  border-right: none;
  border-top: none;
}

.partners__partners-section {
  width: 100%;
  max-width: 400px;
  margin-bottom: 100px;
  position: relative;
}

.partners__partners-border {
  position: relative;
  padding: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 149px;
  padding-bottom: 100px;
}

.partners__partners-general_border {
  padding-top: 170px;
  padding-bottom: 30px;
}

.partners__partners-border::before,
.partners__partners-border::after,
.partners__partners-border > :first-child::after {
  content: "";
  position: absolute;
  width: 45.45px;
  height: 45.45px;
  border: 7px solid #649ab0;
}

.partners__partners-border::before {
  top: 0;
  right: 0;
  border-left: none;
  border-bottom: none;
}

.partners__partners-border::after {
  bottom: 0;
  right: 0;
  border-left: none;
  border-top: none;
}

.partners__partners-border > :first-child::after {
  bottom: 0;
  left: 0;
  border-right: none;
  border-top: none;
}

.parntners__block {
  display: flex;
  gap: 50px;
  flex-direction: column;
}

.partners__partner-image {
  max-width: 100%;
  height: auto;
}

.partners__tech-partners-section {
  width: 100%;
  max-width: 695px;
  margin-bottom: 100px;
  position: relative;
}

.partners__tech-information {
  margin: 0 auto;
  max-width: 100%;
  margin-bottom: 100px;
}

.partners__tech-prize .partners__tech-partners-content {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 60px;
  padding-right: 40px;
}

.partners__tech-information .partners__tech-partners-content {
  padding-left: 70px;
  padding-top: 50px;
}

.partners__tech-subtitle {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 150%;
  color: #649ab0;
  z-index: 1;
  margin-bottom: -35px;
}

.partners__tech-partners-border {
  position: relative;
  padding: 20px;
  justify-content: center;
  align-items: center;
  padding-left: 0px;
}

.partners__tech-partners-border::before,
.partners__tech-partners-border::after,
.partners__tech-partners-border > :first-child::after {
  content: "";
  position: absolute;
  width: 45.45px;
  height: 45.45px;
  border: 7px solid #649ab0;
}

.partners__tech-partners-border::before {
  top: 0;
  right: 0;
  border-left: none;
  border-bottom: none;
}

.partners__tech-partners-border::after {
  bottom: 0;
  right: 0;
  border-left: none;
  border-top: none;
}

.partners__tech-partners-border > :first-child::after {
  bottom: 0;
  left: 0;
  border-right: none;
  border-top: none;
}

.partners__tech-cloud {
  padding: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.partners__tech-cloud div {
  max-width: 55%;
}
.partners__tech-partners-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Две колонки одинаковой ширины */
  grid-template-rows: repeat(2, auto); /* Две строки с автоматической высотой */
  gap: 55px; /* Расстояние между элементами */
  justify-items: center; /* Центрируем элементы в ячейках */
  align-items: center; /* Выравниваем элементы по вертикали в ячейках */
  padding-top: 30px;
}

.partners__tech-information .partners__tech-partners-content {
  grid-template-columns: repeat(3, 1fr); /* Две колонки одинаковой ширины */
}

.partners__tech-partner-image {
  flex: none;
  object-fit: contain;
}

.partners__tech-partner-image:nth-child(1) {
  width: 90%;
  height: 138.1px;
}

.partners__tech-partner-image:nth-child(2) {
  width: 159px;
  height: 78px;
}

.partners__tech-partner-image:nth-child(3) {
  width: 177px;
  height: 68px;
}

.partners__support-section {
  width: 100%;
  margin-bottom: 100px;
  position: relative;
}

.partners__support-subtitle {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 150%;
  color: #649ab0;
  position: absolute;
  top: -55px;
  left: 0;
  z-index: 1;
}

.partners__support-border {
  position: relative;
  padding: 80px;
  margin-top: 40px;
}

.partners__support-border::before,
.partners__support-border::after,
.partners__support-border > :last-child::after {
  content: "";
  position: absolute;
  width: 45.45px;
  height: 45.45px;
  border: 7px solid #649ab0;
}

.partners__support-border::before {
  top: 0;
  right: 0;
  border-left: none;
  border-bottom: none;
}

.partners__support-border::after {
  bottom: 0;
  right: 0;
  border-left: none;
  border-top: none;
}

.partners__support-border > :first-child::before {
  top: 0;
  left: 0;
  border-right: none;
  border-bottom: none;
}

.partners__support-border > :last-child::after {
  bottom: 0;
  left: 0;
  border-right: none;
  border-top: none;
}

.partners__support-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 40px 20px;
  align-items: center;
  justify-items: center;
}

.partners__support-image {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.partners__support-image-1 {
  width: 82px;
  height: 110px;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}
.partners__support-image-2 {
  width: 120px;
  height: 140px;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}
.partners__support-image-3 {
  width: 184px;
  height: 108px;
  grid-column: 3 / 4;
  grid-row: 1 / 2;
}
.partners__support-image-4 {
  width: 218.5px;
  height: 66.4px;
  grid-column: 4 / 5;
  grid-row: 1 / 2;
}
.partners__support-image-5 {
  width: 271px;
  height: 68px;
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}
.partners__support-image-6 {
  width: 253px;
  height: 71px;
  grid-column: 2 / 4;
  grid-row: 2 / 3;
}
.partners__support-image-7 {
  width: 330px;
  height: 99px;
  grid-column: 4 / 5;
  grid-row: 2 / 3;
}
.partners__support-image-8 {
  width: 217px;
  height: 69px;
  grid-column: 1 / 2;
  grid-row: 3 / 4;
}
.partners__support-image-9 {
  width: 210px;
  height: 48px;
  grid-column: 2 / 4;
  grid-row: 3 / 4;
}
.partners__support-image-10 {
  width: 69px;
  height: 95px;
  grid-column: 4 / 5;
  grid-row: 3 / 4;
}

.partners__support-row-2 {
  grid-column: 1 / span 4;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.partners__support-row-3 {
  grid-column: 1 / span 4;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.partners__info-section {
  position: relative;
  width: 100%;
  max-width: 1139px;
  margin: 0 auto;
  padding: 60px 0;
}

.partners__info-border {
  position: relative;
}

.partners__info-border::before,
.partners__info-border::after,
.partners__info-border > :first-child::before,
.partners__info-border > :first-child::after {
  content: "";
  position: absolute;
  width: 45.45px;
  height: 45.45px;
  border: 7px solid #4f4f4f;
}

.partners__info-border::before {
  top: 0;
  right: 0;
  border-left: none;
  border-bottom: none;
}

.partners__info-border::after {
  bottom: 0;
  right: 0;
  border-left: none;
  border-top: none;
}

.partners__info-border > :first-child::before {
  top: 0;
  left: 0;
  border-right: none;
  border-bottom: none;
}

.partners__info-border > :first-child::after {
  bottom: 0;
  left: 0;
  border-right: none;
  border-top: none;
}

.partners__info-content {
  display: flex;
  align-items: center;
  padding: 45px;
}

.partners__info-icon {
  width: 136px;
  height: 119px;
  margin-right: 52px;
}

.partners__info-text {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 169%;
  letter-spacing: 0.005em;
  color: #4f4f4f;
  max-width: 847px;
}

.partners__decorations {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-top: 3rem;
}

.partners__decoration {
  width: 71px;
  height: 71px;
}

.partners__decoration--red {
  background: #d44954;
}

.partners__decoration--blue {
  background: #649ab0;
}

.partners__tech-section {
  display: flex;
  justify-content: space-between;
}

.sber {
  max-width: 90%;
}

.partners__tech-partner-image-searchinform {
  width: 100%;
  padding-top:50px;
}
@media (max-width: 768px) {
  .partners {
    padding: 50px 20px;
    background: url("../assets/partners-mobile.png");
    background-size: 100%;
  }

  .partners__header {
    flex-direction: column;
    align-items: center;
  }

  .partners__title {
    font-size: 25px;
    line-height: 31px;
    text-align: center;
    letter-spacing: 0.1em;
  }

  .partners__rectangle {
    width: 44px;
    height: 44px;
    margin-left: 0;
    margin-top: 20px;
  }

  .partners__background {
    background: none;
  }

  .partners__block {
    flex-direction: column;
  }

  .partners__content {
    flex-direction: column;
    margin-bottom: 0px;
  }

  .partners__join {
    width: 100%;
    margin: 0 auto 50px;
    margin-bottom: 100px;
  }

  .partners__join-border {
    padding: 25px;
  }

  .partners__join-title {
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    width: 100%;
  }

  .partners__join-text {
    font-size: 10px;
    line-height: 150%;
    text-align: center;
    width: 100%;
  }

  .partners__join-button {
    width: 100%;
    font-size: 12px;
    padding: 7px 17px;
    font-weight: var(--font-weight-medium);
  }

  .partners__organizers,
  .partners__official,
  .partners__partners-section,
  .partners__tech-partners-section,
  .partners__support-section {
    width: 100%;
    max-width: 100%;
    margin: 0px auto 50px;
  }

  .partners__subtitle,
  .partners__official-title,
  .partners__tech-subtitle,
  .partners__support-subtitle {
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    position: absolute;
    margin-bottom: 0px;
    margin-top: -15px;
    top: 5px;
    left: 26%;
  }

  .partners__organizers-content,
  .partners__tech-partners-content,
  .partners__support-row {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    flex-direction: row-reverse;
  }

  .partners__organizer-image,
  .partners__partner-image,
  .partners__tech-partner-image,
  .partners__support-image {
    max-width: 100%;
    height: auto;
  }

  .partners__info-section {
    padding: 30px 0;
    margin-top: 300px;
  }

  .partners__tech-section {
    display: block;
    position: relative;
    top: 150px;
  }

  .partners__organizer-image--nastavniki {
    width: 60px;
  }

  .partners__organizer-image--arsib {
    width: 120px;
  }

  .partners__tech-partner-image:nth-child(1) {
    width: 88%;
  }

  .partners__tech-partner-image:nth-child(3) {
    width: 115px;
  }
  .partners__support-section {
    max-width: 332px;
    margin: 0 auto 50px;
    position: relative;
    top: 200px;
  }

  .partners__support-subtitle {
    font-size: 15px;
    line-height: 150%;
    text-align: center;
    position: absolute;
    margin-bottom: 20px;
  }

  .partners__support-border {
    padding: 20px;
  }

  .partners__support-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .partners__support-image {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }

  .partners__support-image-1,
  .partners__support-image-2,
  .partners__support-image-3,
  .partners__support-image-4,
  .partners__support-image-5,
  .partners__support-image-6,
  .partners__support-image-7,
  .partners__support-image-8,
  .partners__support-image-9,
  .partners__support-image-10 {
    width: 100%;
    max-width: 120px;
    grid-column: auto;
    grid-row: auto;
    justify-self: center;
  }

  .partners__support-image-9 {
    grid-column: 1 / 3;
  }

  .partners__support-image-10 {
    grid-column: 1 / 3;
    grid-row: auto;
  }

  .partners__support-row:nth-child(5) .partners__support-image,
  .partners__support-row:nth-child(6) .partners__support-image {
    width: calc(50% - 10px);
    max-width: 120px;
  }
  .partners__info-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .partners__info-icon {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .partners__info-text {
    font-size: 10px;
    line-height: 169%;
  }

  .partners__decorations {
    display: none;
  }

  .partners__organizers-border {
    padding: 0px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .partners__official {
    position: relative;
    top: 100px;
  }

  .partners__official-title {
    left: 100px;
  }

  .partners__join {
    height: auto;
    margin-bottom: 0px;
  }

  .partners__join-border {
    padding-bottom: 70px;
  }

  .partners__subtitle {
    left: 33%;
  }

  .partners__tech-subtitle {
    left: 75px;
  }

  .partners__partners-section .partners__subtitle {
    left: 38%;
  }

  .partners__partners-section .partners__subtitle-general {
    left: 10%;
    max-width: 300px;
  }

  .partners__tech-partners-section {
    margin-top: 100px;
  }

  .partners__organizers-border > :first-child::before,
  .partners__official-border > :first-child::before,
  .partners__partners-border > :first-child::before,
  .partners__tech-partners-border > :first-child::before,
  .partners__support-border > :first-child::before {
    content: "";
    position: absolute;
    width: 45.45px;
    height: 45.45px;
    border: 7px solid #649ab0;
    top: 0;
    left: 0;
    border-right: none;
    border-bottom: none;
  }

  .partners__support-subtitle {
    left: 31%;
  }

  .partners__partners-general_border {
    padding-top: 50px;
  }

  .partners__tech-information .partners__tech-partners-content {
    padding-left: 10px;
  }

  .partners__tech-information .partners__tech-partners-content {
    grid-template-columns: repeat(2, 1fr);
    padding-left: 40px;
  }

  .partners__tech-prize .partners__tech-partners-content {
    padding: 10px;
    padding-left: 20px;
  }
  
  .newno {
    display: block;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px;
  }

  .newno div {
    max-width: 50%;
  }

  .newno_link {
    display: none;
  }
}
