.information {
  position: relative;
  background: url("../assets/rules_top.png") center top no-repeat;
  width: 100%;
  background-size: contain;
}

.information__title {
  font-family: "Quantum", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 71px;
  line-height: 89px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 100px;
}

.information__bottom {
  background: url("../assets/rules_bottom.png") center top no-repeat;
  padding-bottom: 1320px;
  background-size: cover;
}

.information__rules {
  list-style-type: none;
  padding: 0;
  max-width: 1000px;
  margin: 0 auto;
}

.information__rule {
  display: flex;
  align-items: flex-start;
  margin-bottom: 70px;
}

.information__rule-marker {
  width: 145px;
  height: 145px;
  background: #649ab0;
  margin-right: 50px;
  flex-shrink: 0;
  font-family: "Quantum", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 71px;
  line-height: 89px;
  text-align: center;
  color: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
}

.information__rule-text {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  color: #ffffff;
  margin: 0;
  padding-top: 40px;
}

.information__faq-title {
  font-family: "Quantum", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 71px;
  line-height: 89px;
  text-align: center;
  color: #ffffff;
  margin-top: 100px;
}

@media (max-width: 768px) {
  .information {
    background: url("../assets/rules_top-mobile.png") right top no-repeat;
  }

  .information__title,
  .information__faq-title {
    font-size: 48px;
    line-height: 60px;
  }

  .information__bottom {
    background: url("../assets/rules_bottom-mobile.png") center top no-repeat;
    padding-bottom: 690px;
    background-size: cover;
  }

  .information__rule {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .information__rule-marker {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .information__rule-text {
    font-size: 30px;
    line-height: 35px;
    padding-top: 0;
  }
}

.faq-list {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 16px;
}

.faq-item {
  margin-bottom: 20px;
  border-bottom: 4px solid #4f7892;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  padding: 20px 0;
}

.faq-question span {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: #649ab0;
  flex: 1;
  padding-right: 20px;
}

.faq-toggle-wrapper {
  flex: 0 0 71px;
}

.faq-toggle {
  position: relative;
  width: 71px;
  height: 71px;
}

.faq-toggle-vertical,
.faq-toggle-horizontal {
  position: absolute;
  background: #649ab0;
}

.faq-toggle-vertical {
  width: 23px;
  height: 71px;
  left: 24px;
  top: 0;
}

.faq-toggle-horizontal {
  width: 71px;
  height: 23px;
  left: 0;
  top: 24px;
}

.faq-answer {
  display: none;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  padding: 20px 0;
}

.faq-item.active .faq-answer {
  display: block;
}

.faq-item.active .faq-toggle-horizontal {
  display: none;
}

.result img {
  width: 100%;
  padding-top: 20px;
}

.result_mobile img {
  width: 100%;
  display: none;
  padding-top: 20px;
}

.wallpapers {
  padding-top: 100px;
  text-align: center;
}

.wallpapers__title {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: var(--font-weight-normal);
  font-size: 55px;
  line-height: 69px;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-white);
}

.wallpapers__description {
  margin-top: 52px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: var(--font-weight-normal);
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  font-variant: small-caps;
  color: var(--color-white);
}

.wallpapers__download {
  margin-top: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wallpapers__icon {
  width: 189px;
  height: 227px;
  display: block;
}

.wallpapers__icon-red {
  background: url("../assets/doc-red.png");
  background-size: 189px 227px;
}

.wallpapers__download-title {
  margin-top: 57px;
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: var(--font-weight-normal);
  font-size: 38px;
  line-height: 48px;
  text-align: center;
  color: var(--color-white);
}

@media (max-width: 768px) {
  .faq-question span {
    font-size: 20px;
    line-height: 25px;
    width: 252px;
  }

  .faq-toggle-wrapper {
    flex: 0 0 22px;
  }

  .faq-toggle {
    width: 22px;
    height: 22px;
  }

  .faq-toggle-vertical,
  .faq-toggle-horizontal {
    width: 7px;
    height: 22px;
  }

  .faq-toggle-vertical {
    left: 8px;
  }

  .faq-toggle-horizontal {
    width: 22px;
    height: 7px;
    top: 7px;
  }

  .faq-answer {
    font-size: 15px;
    line-height: 20px;
    width: 311px;
  }

  .wallpapers__title {
    font-size: 40px;
    line-height: 50px;
    padding: 0 20px;
  }

  .wallpapers__description {
    font-size: 20px;
    line-height: 24px;
    padding: 0 20px;
  }

  .wallpapers__icon {
    width: 150px;
    height: 180px;
    background-size: 150px 180px;
  }

  .wallpapers__download-title {
    font-size: 30px;
    line-height: 38px;
  }
}
