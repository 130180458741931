.about {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--spacing-medium);
}

.about__title {
  font-family: var(--font-secondary);
  font-size: var(--font-size-xl);
  color: var(--color-secondary);
  text-align: center;
  margin-bottom: var(--spacing-large);
}

.about__description {
  max-width: 977px;
  margin: 0 auto var(--spacing-large);
  font-family: var(--font-primary);
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-normal);
  line-height: 34px;
  text-align: center;
  color: var(--color-white);
}

.about__subtitle {
  font-family: var(--font-secondary);
  font-size: 52px;
  color: var(--color-white);
  text-align: center;
  margin: var(--spacing-large) 0;
}

.about__history {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-large);
}

.about__year {
  display: flex;
  gap: var(--spacing-large);
  align-items: flex-start;
}

.about__year-image {
  width: 345px;
  height: 345px;
  object-fit: cover;
}

.about__year-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-medium);
}

.about__text h3,
.about__media h3,
.about__tasks h3 {
  font-family: var(--font-secondary);
  font-size: 29px;
  color: var(--color-secondary);
  margin-bottom: var(--spacing-small);
}

.about__text ul,
.about__media ul,
.about__tasks ul {
  list-style: none;
  padding: 0;
}

.about__text li,
.about__media li,
.about__tasks li {
  font-family: var(--font-secondary);
  font-size: 18px;
  line-height: 40px;
}

.about__text a,
.about__media a,
.about__tasks a {
  color: var(--color-white);
  text-decoration: none;
  transition: color 0.3s ease;
}

.about__text a:hover,
.about__media a:hover,
.about__tasks a:hover {
  color: var(--color-secondary);
}

.about__year:nth-child(n + 3) .about__year-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-large);
  align-items: start; /* Добавлено */
}

.about__year:nth-child(n + 3) .about__text,
.about__year:nth-child(n + 3) .about__media {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Добавлено */
  padding-top: 80px;
  width: 220px;
}

.about__year:nth-child(n + 3) .about__text ul,
.about__year:nth-child(n + 3) .about__media ul {
  list-style: none;
  padding: 0;
  margin: 0; /* Добавлено */
}

.about__year:nth-child(n + 3) .about__text li,
.about__year:nth-child(n + 3) .about__media li {
  font-size: 24px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .about {
    padding: 10px;
  }

  .about__title {
    font-size: 25px;
    line-height: 31px;
    margin-bottom: 24px;
    text-align: center;
  }

  .about__description {
    font-size: 12px;
    line-height: 14px;
    max-width: 331px;
    margin: 0 auto 50px;
  }

  .about__subtitle {
    font-size: 25px;
    line-height: 31px;
    margin-bottom: 40px;
    text-align: center;
  }

  .about__history {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .about__year {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
  }

  .about__year:nth-child(even) {
    flex-direction: row-reverse;
  }

  .about__year-image {
    width: 93px;
    height: 93px;
  }

  .about__year-content {
    flex: 1;
    max-width: 258px;
  }

  .about__text h3,
  .about__media h3,
  .about__tasks h3 {
    font-size: 14px;
    line-height: 18px;
    margin-top: 0; /* Добавлено */
    margin-bottom: 8px;
  }

  .about__text li,
  .about__media li,
  .about__tasks li {
    font-size: 8px;
    line-height: 10px;
    margin-bottom: 6px;
  }

  .about__year:nth-child(n + 3) .about__text li,
  .about__year:nth-child(n + 3) .about__media li {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .about__year:nth-child(n + 3) .about__year-content {
    display: flex;
    align-items: center; /* Выравнивание по центру */
    height: 93px; /* Высота равна высоте картинки */
  }

  .about__year:nth-child(n + 3) .about__text,
  .about__year:nth-child(n + 3) .about__media {
    flex: 1;
    padding-top: 0px;
  }

  .about__year:nth-child(n + 3) .about__text ul,
  .about__year:nth-child(n + 3) .about__media ul {
    margin: 0;
    padding: 0;
  }

  .about__year:nth-child(n + 3) .about__text li,
  .about__year:nth-child(n + 3) .about__media li {
    font-size: 8px;
    line-height: 10px;
    margin-bottom: 4px;
  }

  .about__year:nth-child(n + 3) .about__text li:last-child,
  .about__year:nth-child(n + 3) .about__media li:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .about__year-content {
    justify-content: center;
    margin: 0 auto;
    max-width: 800px;
  }

  .about__year {
    justify-content: center;
    gap: var(--spacing-large);
  }
}

@media (min-width: 768px) {
  .about__year-content {
    justify-content: center;
    margin: 0 auto;
    max-width: 800px;
    align-items: flex-start; /* Добавлено */
  }

  .about__year {
    justify-content: center;
    gap: var(--spacing-large);
  }

  /* Добавлено */
  .about__text,
  .about__media,
  .about__tasks {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .about__text h3,
  .about__media h3,
  .about__tasks h3 {
    margin-top: 0;
    height: 36px; /* Фиксированная высота для заголовков */
    display: flex;
    align-items: center;
  }
}

.about__contacts {
  margin-top: var(--spacing-medium);
  line-height: 1.5;
}

.about__contacts a {
  color: var(--color-secondary);
  text-decoration: none;
  transition: color 0.3s ease;
}

.about__contacts a:hover {
  color: var(--color-primary);
}

@media (max-width: 767px) {
  .about__contacts {
    font-size: 12px;
  }
}
