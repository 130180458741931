.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;

}

.home__brand-name {
  font-family: var(--font-secondary);
  font-size: var(--font-size-xxl);
  line-height: 152px;
  text-align: center;
  color: var(--color-secondary);
  margin-bottom: 0;
}

.home__brand-year {
  font-family: var(--font-secondary);
  font-size: var(--font-size-xxl);
  line-height: 175px;
  text-align: center;
  color: var(--color-white);
  margin-top: 0;
}

.home__description {
  width: 100%;
  max-width: var(--max-width);
  background: url("../assets/building.png") center top no-repeat;
  background-size: 100%;
}

.registration {
  padding: 20px;
  box-sizing: border-box;
  padding-right: 120px;
  padding-top: 115px;
}

.registration-block {
  display: flex;
  justify-content: flex-end;
}

.registration__link {
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.registration__title {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: var(--font-weight-normal);
  font-size: 49px;
  line-height: 61px;
  text-align: center;
  color: var(--color-white);
  text-decoration: none;
}

.registration__subtitle {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-small);
  line-height: 32px;
  text-align: center;
  color: var(--color-primary);
  padding-top: 3px;
}

.registration__ru {
  color: var(--color-white);
  padding-right: 1px;
}

.registration__foreign {
  color: var(--color-secondary);
  padding-left: 2px;
}

.registration__slash {
  font-weight: bold;
}

.registration-block_img {
  max-width: 495px;
  width: 100%;
}

.about__title {
  font-family: var(--font-secondary);
  font-size: var(--font-size-xl);
  line-height: 89px;
  text-align: center;
  color: var(--color-white);
}

.about {
  padding-top: 450px;
}

.about__description {
  font-weight: var(--font-weight-medium);
  font-size: 57px;
  line-height: 67px;
  text-align: center;
  color: var(--color-primary);
}

.about__text {
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-medium);
  line-height: 10px;
  text-align: center;
  color: var(--color-white);
}

.schedule {
  padding-top: 505px;
  width: 100%;
}

.schedule__title {
  font-family: var(--font-secondary);
  font-size: 4.44rem;
  line-height: var(--line-height-large);
  text-align: center;
  color: var(--color-white);
  margin-bottom: var(--spacing-medium);
}

.schedule__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}

.schedule__item {
  display: flex;
  width: 100%;
  max-width: 1160px;
  margin-bottom: var(--spacing-large);
  justify-content: space-between;
}

.schedule__item--left {
  flex: 0 0 40%;
}

.schedule__item--right {
  flex: 0 0 60%;
}

.schedule__info {
  display: flex;
  align-items: center;
  gap: 2.25rem;
  margin-bottom: var(--spacing-small);
}

.schedule__task-based {
  font-family: var(--font-primary);
  font-weight: var(--font-weight-medium);
  font-size: 2.125rem;
  line-height: 1.18;
  color: var(--color-primary);
}

.schedule__online {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.69rem 1.69rem;
  border: 1px solid var(--color-primary);
  border-radius: 79px;
}

.schedule__online span {
  font-family: var(--font-primary);
  font-weight: var(--font-weight-medium);
  font-size: 2.125rem;
  line-height: 1.18;
  color: var(--color-primary);
}

.schedule__date {
  font-family: var(--font-primary);
  font-weight: var(--font-weight-medium);
  font-size: 4.0625rem;
  line-height: var(--line-height-normal);
  color: var(--color-white);
  display: inline;
}

.schedule__description {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: var(--font-weight-normal);
  font-size: 1.8125rem;
  line-height: var(--line-height-normal);
  color: var(--color-white);
  max-width: 574px;
  margin-bottom: var(--spacing-small);
}

.schedule__additional-info {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: var(--font-weight-normal);
  font-size: 1.8125rem;
  line-height: var(--line-height-normal);
  color: var(--color-white);
  max-width: 629px;
  text-align: left;
}

.schedule__item--hours {
  color: var(--color-primary);
}

.schedule__info--red,
.schedule__info--red span {
  color: var(--color-secondary);
  border-color: var(--color-secondary);
}

.place {
  position: relative;
  text-align: center;
  padding-top: 231.10%;
}

.place__item {
  height: 400px;
  background: url("../assets/place.png") no-repeat center;
  background-size: 324px 357px;
  margin: 0 auto;
}

.place__description {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: var(--font-weight-medium);
  font-size: 28px;
  line-height: 33px;
  color: var(--color-white);
  max-width: 663px;
  margin: 0 auto;
  padding-left: 645px;
  overflow: hidden;
  max-height: 480px;
}

.place__description iframe {
  max-height: 482px;
}

.documents {
  padding-top: 985px;
}

.documents__title {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-xl);
  line-height: 89px;
  text-align: center;
  color: var(--color-white);
  display: block;
}

.documents__items {
  display: flex;
  justify-content: space-between;
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 20px;
}

.documents__item {
  flex: 0 1 calc(33.333% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 340px;
}

.documents__item--icon {
  width: 189px;
  height: 227px;
  display: block;
}

.documents__item--icon-red {
  background: url("../assets/doc-red.png");
  background-size: 189px 227px;
}

.documents__item--icon-blue {
  background: url("../assets/doc-blue.png");
  background-size: 189px 227px;
}

.documents__item--title {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: var(--font-weight-normal);
  font-size: 38px;
  line-height: 48px;
  text-align: center;
  color: var(--color-white);
}

.documents__item--description {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: var(--font-weight-normal);
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: var(--color-white);
}

@media (max-width: 768px) {
  .home__description {
    background: url("../assets/building-mobile.png") center top no-repeat;
    background-size: cover;
    position: relative;
  }
  .documents__items {
    justify-content: center;
  }

  .documents__item {
    flex: 0 1 100%;
    margin-bottom: 60px;
  }

  .home__brand-name,
  .home__brand-year {
    font-size: 48px;
    line-height: 1.2;
  }

  .registration {
    padding-right: 55px;
    padding-top: 46%;
    max-width: 73%;
  }

  .registration__title {
    font-size: 17px;
    line-height: 1.2;
    text-transform: uppercase;
    color: var(--color-white);
  }

  .registration__subtitle {
    margin: 0px;
    font-weight: var(--font-weight-medium);
  }
  
  .about {
    padding-top: 35%;
  }

  .about__title {
    font-size: var(--font-size-large);
    line-height: 1.2;
  }

  .about__description {
    font-size: 23px;
    line-height: 1.2;
    padding-bottom: 15px;
  }

  .about__text {
    font-size: var(--font-size-small);
    line-height: 10px;
    font-weight: var(--font-weight-normal);
  }

  .schedule {
    padding-top: 60%;
  }

  .schedule__title {
    font-size: var(--font-size-large);
  }

  .schedule__content {
    padding-top: 10px;
  }

  .schedule__item {
    flex-direction: column;
    align-items: center;
    margin-bottom: 35px;
  }

  .schedule__item--left,
  .schedule__item--right {
    flex: 1 1 100%;
    width: 100%;
    text-align: center;
  }

  .schedule__info {
    justify-content: center;
    flex-wrap: wrap;
  }

  .schedule__task-based,
  .schedule__online span {
    font-size: 15px;
  }

  .schedule__date {
    font-size: 40px;
    border-bottom: 1px solid #d9d9d9;
  }

  .schedule__description,
  .schedule__additional-info {
    font-size: var(--font-size-small);
    max-width: 100%;
    text-align: center;
  }

  .place {
    padding-top: 1535px;
  }

  .place__item {
    height: 300px;
    background-size: contain;
  }

  .place__description {
    font-size: 18px;
    line-height: 1.5;
    max-width: 344px;
    padding: 0px;
    max-height: 253px;
  }

  .place__description iframe {
    max-height: 482px;
  }
  
  .documents {
    padding-top: 114%;
  }

  .documents__title {
    font-size: var(--font-size-large);
    line-height: 1.2;
  }

  .documents__items {
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
  }

  .documents__item {
    margin-bottom: 40px;
  }

  .documents__item--title {
    font-size: 18px;
    line-height: 1.2;
  }

  .documents__item--description {
    font-size: var(--font-size-small);
    line-height: 14px;
    font-weight: var(--font-weight-normal);
  }

  .place__description iframe {
    max-height: 253px;
  }
}

@media (max-width: 480px) {
  .schedule__item {
    flex-direction: column;
  }

  .schedule__item--left,
  .schedule__item--right {
    width: 100%;
  }
}
