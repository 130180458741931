.results {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--spacing-medium);
}

.results__header {
  text-align: center;
  margin-bottom: var(--spacing-large);
}

.results__title {
  font-family: var(--font-secondary);
  font-size: var(--font-size-xl);
  color: var(--color-secondary);
  line-height: var(--line-height-normal);
}

.results__description {
  font-family: var(--font-primary);
  font-size: var(--font-size-medium);
  line-height: var(--line-height-large);
  text-align: center;
  max-width: 977px;
  margin: 0 auto var(--spacing-large);
}

.results__stages {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-large);
}

.results__stage {
  text-align: center;
}

.results__stage-title {
  font-family: var(--font-secondary);
  font-size: var(--font-size-large);
  color: var(--color-secondary);
  margin-bottom: var(--spacing-medium);
}

.results__video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  width: 100%;
  max-width: 1062px; /* Максимальная ширина контейнера */
  margin: 0 auto;
}

.results__video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  max-height: 598px; /* Максимальная высота видео */
  margin: auto;
}

@media (min-width: 1062px) {
  .results__video-wrapper {
    height: 598px; /* Фиксированная высота для больших экранов */
    padding-bottom: 0;
  }
}

@media (max-width: 767px) {
  .results {
    padding: var(--spacing-small);
    overflow-x: hidden;
    width: 100%;
  }



  .results__title {
    font-size: var(--font-size-large);
  }

  .results__description {
    font-size: var(--font-size-small);
    padding: 0 var(--spacing-small);
  }

  .results__video-wrapper {
    width: 100vw;
    max-width: none;
  }
}
